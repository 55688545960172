import React from 'react';
import { Box, Modal, Button } from "@material-ui/core";
import Add from "@material-ui/icons/Close"
import { useDispatch } from 'react-redux';
import { DeleteProgramWeeksAction, GetListProgramWeeksAction } from 'Redux/Action/programBuilderAct';
import useApi from 'common/hooks/useApi'

function DeleteWeek(props) {
    const { value } = props
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);

    const [, clearWeekData] = useApi(
            {
              method: 'POST',
              url: 'builder/program_weeks/empty_week_data/',
            },
            { manual: true }
          )

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = () => {
        const body = {id: value}
        clearWeekData({
            data: body,
        }).then((response) => {
        if(response?.status === 200){
            dispatch(DeleteProgramWeeksAction(value))
            setOpen(false)
            setTimeout(() => {
                dispatch(GetListProgramWeeksAction())
            }, 500);
        }})
    }

    return (
        <Box>
            <Box
                style={{
                    textAlign: "center",
                    padding: "32px ",
                    lineHeight: "normal",
                }}
            >
                <Button className="save_button" style={{ color: "red" }} onClick={handleOpen}><Add /></Button>
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                disableEnforceFocus
            >
                <Box
                    style={{
                        backgroundColor: "white",
                        border: "2px solid #000",
                        boxShadow: 24,
                        padding: '30px',
                        minWidth: '40vw',
                        overflow: 'scroll'
                    }}
                >
                    <h2>Delete this week?</h2>
                    <Box style={{ textAlign: 'center', margin: '40px 0 10px 0 ' }}>
                        <Button variant="contained" color="primary" onClick={() => handleDelete()} style={{ marginLeft: "2vw", width: "10vw", border: "2px solid #0B545E" }}>
                            Yes
                        </Button>
                        <Button variant="text" style={{ color: '#0000008A' }} onClick={handleClose}>
                            Cancel
                        </Button>

                    </Box>
                </Box>
            </Modal >
        </Box >
    )
}

export default DeleteWeek
