/* eslint-disable */
import React, { useEffect, useState, useContext, useMemo, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from '@material-ui/icons/Search';
import { Box, Divider, Modal, TextField, Button, InputLabel, FormControl, Select, MenuItem, Collapse, Popover, Typography, FormHelperText, CircularProgress, Checkbox } from "@material-ui/core";
import TablePagination from '@material-ui/core/TablePagination';
import { useFormik } from "formik";
import Add from "@material-ui/icons/Add";
import Remove from "@material-ui/icons/Remove";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CustomCheckbox from "./CustomCheckbox";
import { activityGroupDetailData, activityDetailData, activityUpdateDetailData, deleteActivity, resetActivityAndGroup, activityGroupUpdateDetailActivityData, activityGroupAddActivity, commonActivityData, deleteCommonData, editBlockData, editBlockExerciseDetails, editActivityGroupUpdateDetailActivityData, deleteEditedCommonData, AddBlockData, GetExerciseAction, GetListExerciseAction, CreateProgramExerciseAction, DeleteProgramExerciseAction, GetListProgramExerciseAction, ClearSingleBlockProgramExercise, CreateBlocksAction, GetListBlocksAction, DeleteBlocksAction, UpdateBlocksAction, UpdateProgramExerciseAction, GetListProgramDaysAction } from "../../Redux/Action/programBuilderAct"
import * as Yup from 'yup';
import CreateIcon from '@material-ui/icons/CreateRounded';
import DeleteIcon from '@material-ui/icons/Close'
import { consoleSandbox } from "@sentry/utils";
import { DeleteProgramExerciseSaga } from "Redux/Sagas/ProgramBuilderSagas";
import useApi from 'common/hooks/useApi'
import { LayoutContext, app } from 'common/ducks'
import styled from 'styled-components'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import BtnCellRenderer from './BtnCellRenderer.js'
import SelectRenderer from './selectRenderer.js'
import CheckboxRenderer from './checkboxRenderer.js'
import CopyRenderer from './copyRenderer.js'
import Switch from '@mui/material/Switch'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CreateExercise from './CreateExercise.js'

function SidePanelCheckBox({ label }) {
    return <CustomCheckbox
        textStyle={{
            py: 1.5,
            px: 3,
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        }}
        label={label}
    />
};

const validationBlockSchema = Yup.object().shape({
    Name: Yup.string().required('Required'),
});

const activityDetailName = [
{label: "Exercise", name: "exercise" },
{ label: "Sets", name: "set_number" },
{ label: "Reps", name: "reps" },
{ label: "Weight (lbs)", name: "weight_lbs" },
{ label: "Notes", name: "notes" },
{ label: "Kpi", name: "kpi" }]
const activityDetailName2 = [
{label: "Work Time (sec)", name: "work_time_seconds" },
{ label: "Rest Time (sec)", name: "rest_time_seconds" },
{ label: "Distance (m)", name: "distance_meters" },
{ label: "1RM%", name: "one_rep_max_percent" },
{ label: "RIR", name: "reps_in_reserve" },
{ label: "Tempo", name: "tempo" },
{ label: "Exercise Type", name: "ExerciseType" }]

function SidebarBlockTab() {
    const dispatch = useDispatch()
    const { blockInfo, exerciseInfo, commonDetails, editBlockExercise, programExercise, singleBlockExercise } = useSelector(state => state.rootReducer.programBuilder)
    const [open, setOpen] = React.useState(false);
    const [activityOpen, setActivityOpen] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openaActivityDetailName, setopenaActivityDetailName] = useState(false)
    const [activityGroupOpen, setActivityGroupOpen] = useState(false)
    const [editActivityOpen, setEditActivityOpen] = useState(false)
    const [editProgramExerciseOpen, setEditProgramExerciseOpen] = useState(false)
    const [editActivityGroupOpen, setEditActivityGroupOpen] = useState(false)
    const [editActivityGroupNameOpen, setEditActivityGroupNameOpen] = useState(false)
    const [activityDetailId, setActivityDetailId] = useState(null)
    const [programExerciseId, setProgramExerciseId] = useState(null)
    const [activityGroupDetailId, setActivityGroupDetailId] = useState(null)
    const [activityGroupDetailActvityIndex, setActivityGroupDetailActvityIndex] = useState(null)
    const [activityGroupDetailActvityGroupIndex, setActivityGroupDetailActvityGroupIndex] = useState(null)
    const [selectedActivityDetail, setSelectedActivityDetail] = useState([...activityDetailName])
    const [selectedActivityDetail2, setSelectedActivityDetail2] = useState([...activityDetailName2])
    const [tempactivityGroupAddActivity, setTempActivityGroupAddActivity] = useState({ Exercise: "", Set: "" })
    const [tempActivityAddActivity, setTempActivityAddActivity] = useState({ Exercise: "", Set: "" })
    const [addActivityGroupActivity, setAddActivityGroupActivity] = useState(false);
    const [commonActivity, setCommonActivity] = useState([]);
    const [commonGroup, setCommonGroup] = useState([]);
    const [editBlockData, setEditblockData] = useState({});
    const [editCommonActivity, setEditCommonActivity] = useState([]);
    const [editCommonGroup, setEditCommonGroup] = useState([]);
    const [deleteIdList, setDeleteIdList] = useState(null);
    const [exerciseData, setExerciseData] = useState([]);
    const [editExerciseData, setEditExerciseData] = useState([]);
    const [deleteIdProgramExerciseList, setDeleteIdProgramExerciseList] = useState(null)
    const { setAppLoading, setDrawerOptions } = useContext(LayoutContext)
    const [searchInput, setSearchInput] = useState("")
    const gridRef = useRef()
    const [newBlockCreated, setNewBlockCreated] = useState(false)
    const [expanded, setExpanded] = React.useState(false)


    useEffect(() => {
        dispatch(GetListBlocksAction())
    }, [])

    const [columnDefs] = useState([
        {
            headerName: "Exercise",
            field: "exercise",
            cellStyle: {overflow: 'visible'},
            cellRenderer: SelectRenderer,
            cellRendererParams: (params) => {
                params.source = 'sidebar'
                return {data: params};
            },
            valueFormatter: (params) => {
                return params.value.name
            },
            rowDrag: true,
            pinned: 'left',
            width: 300,
        },
        { field: "set_number", headerName: 'Sets', width: 100, editable: true, },
        { field: "reps", width: 100, editable: true, },
        { field: "weight_lbs", headerName: 'Weight (lbs)', width: 150, editable: true, },
        {
            field: "notes",
            width: 200,
            height: 200,
            editable: true,
            cellEditorPopup: true,
            cellEditor: 'agLargeTextCellEditor',
            cellEditorParams: {
                rows: 20,
                cols: 20,
            }
        },
        {
            field: "kpi",
            headerName: 'KPI',
            width: 100,
            cellRenderer: CheckboxRenderer,
        },
        { field: "work_time_seconds", headerName: 'Work Time (s)', width: 125, editable: true, },
        { field: "rest_time_seconds", headerName: 'Rest Time (s)', width: 125, editable: true, },
        { field: "distance_meters", headerName: 'Distance (m)', width: 150, editable: true, },
        { field: "one_rep_max_percent", headerName: '1RM%', width: 100, editable: true, },
        { field: "reps_in_reserve", headerName: 'RIR', width: 100, editable: true, },
        { field: "tempo", width: 100, editable: true, },
        {
          field: '',
          width: 75,
          cellRenderer: CopyRenderer,
        },
        {
          field: '',
          width: 75,
          cellRenderer: BtnCellRenderer,
        }
    ])

    function onRowDragEnd(e) {
        let exercises_pk = []
        gridRef.current.api.forEachNode((rowNode, index) => {
            exercises_pk.push(rowNode.data.id)
        })
        let payload = {
            name: formik.values.Name,
            secondaryName: formik.values.SecondaryName,
            exercises_pk: exercises_pk,
            description: formik.values.Description,
            archived: formik.values.Archived,
        }
        dispatch(UpdateBlocksAction({ updateData: payload, id: editBlockData.id }))
        setTimeout(() => {
            dispatch(GetListBlocksAction())
        }, 300)
    }
    let list = []

    const [{ loading: copyLoading }, copyBlock] = useApi(
            {
              method: 'POST',
              url: 'builder/blocks/copy_block/',
            },
            { manual: true }
          )

    useEffect(() => {
      setAppLoading(copyLoading)
    }, [setAppLoading, copyLoading])

    if (searchInput.length > 0) {
        list = blockInfo.data?.filter((block) => {
            RegExp.quote = function(str) {
                 return str.replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1");
            }
            if (block?.name.match(new RegExp(RegExp.quote(searchInput), "i"))){
                return block?.name.match(new RegExp(RegExp.quote(searchInput), "i"))
            }
            if (block?.secondaryName?.match(new RegExp(RegExp.quote(searchInput), "i"))){
                return block?.secondaryName?.match(new RegExp(RegExp.quote(searchInput), "i"))
            }
        })
    } else {
        list = blockInfo.data
    }

    const id = openaActivityDetailName ? 'simple-popover' : undefined;

    useEffect(() => {
        let commonActdata = commonDetails;
        let newActData = commonActdata?.filter(data => !data?.details);
        let newActGrpData = commonActdata?.filter(data => data?.details);
        setCommonActivity(newActData);
        setCommonGroup(newActGrpData);
    }, [commonDetails]);

    useEffect(() => {
        let commonEditdata = editBlockExercise;
        let newEditActData = commonEditdata?.filter(data => !data?.details);
        let newEditActGrpData = commonEditdata?.filter(data => data?.details);

        setEditCommonActivity(newEditActData);
        setEditCommonGroup(newEditActGrpData);
    }, [editBlockExercise]);

    useEffect(() => {
        setSelectedActivityDetail([...activityDetailName])
        setSelectedActivityDetail2([...activityDetailName2])
    }, [])

    useEffect(() => {
        let findObj = []
        if (newBlockCreated)    {
            setTimeout(() => {
                if (blockInfo?.data.length > 0 && blockInfo?.data[0]) {
                    const list = blockInfo?.data.map((item) => Number(item?.id))
                    const newId = Math.max(...list)
                    let findObj =  blockInfo?.data.find(item => item?.id === newId)
                    setEditblockData(findObj)
                    if (findObj?.exercises?.length > 0) {
                        const tempBlock = []
                        findObj?.exercises?.map((data1) => {
                            const tempObj = {
                                id: data1.id,
                                exercise: data1.exercise,
                                exercise_pk: data1.exercise.id,
                                set_number: data1.set_number,
                                weight_lbs: Number(data1.weight_lbs),
                                distance_meters: Number(data1.distance_meters),
                                one_rep_max_percent: Number(data1.one_rep_max_percent),
                                reps: data1.reps,
                                reps_in_reserve: data1.reps_in_reserve,
                                tempo: data1.tempo,
                                work_time_seconds: data1.work_time_seconds,
                                rest_time_seconds: data1.rest_time_seconds,
                                notes: data1.notes,
                                kpi: data1.kpi,
                                block_id: newId,
                            }
                            tempBlock.push(tempObj)
                        })
                        setEditExerciseData(tempBlock)
                    }
                setNewBlockCreated(false)
                formikActivity.handleSubmit()
                }
            }, 500)
        }
    }, [blockInfo?.data])

    const handleDeleteBlockData = (id) => {
        setDeleteIdList(id)
        dispatch(DeleteBlocksAction(id))
    }
    const handleDeleteProgramExerciseData = (id) => {
        setDeleteIdProgramExerciseList(id)
        dispatch(DeleteProgramExerciseAction(id))
        const editedExerciseId = editExerciseData?.findIndex((s) => s.id === id)
        editExerciseData.splice(editedExerciseId, 1);
        if (editExerciseData.length > 0){
            setEditblockData(...editExerciseData)
        }
    }

    const handleChange = () => {
        setExpanded(!expanded)
    }

    const handleOpen = () => {
        dispatch(GetListExerciseAction())
        setEditblockData({})
        setEditExerciseData([])
        setOpen(true);
        setEditblockData({});
    };

    const handleActivityOpen = () => {
        setActivityOpen(true)
    }

    const handleActivityDropOpen = (event) => {
        setAnchorEl(event.currentTarget);
        setopenaActivityDetailName(true)
    }

    const handleActivityDropClose = () => {
        setopenaActivityDetailName(false)
    }

    const handleActivityGroupOpen = () => {
        setActivityGroupOpen(true)
    }

    const getExerciseName = (id) => {
        const exerciseName = exerciseInfo.find((data) => data.id === id.id)
        return  exerciseName?.name;
    }

    const editActivityDetail = (data) => {
        setTimeout(() => {
            setActivityDetailId(data)
            setEditActivityOpen(true)
        }, 500)
    }
    const editProgramExerciseDetail = (data) => {
        setProgramExerciseId(data)
        setEditProgramExerciseOpen(true)
    }

    const deleteActivityDetail = (id) => {
        if (Object?.keys(editBlockData)?.length === 0) {
            dispatch(deleteCommonData(id))
        } else {
            dispatch(deleteEditedCommonData(id))
        }
    }

    const handleRemoveActivity = () => {
        const temp1 = [...selectedActivityDetail];
        const temp2 = [...selectedActivityDetail2];
        if (temp1?.length > 2) {
            const lastData = temp1.pop()
            temp2.push(lastData)
            setSelectedActivityDetail2(temp2)
            setSelectedActivityDetail(temp1)
        }
    }

    const handleActivityDetailNameChange = (data) => {
        const temp = [...selectedActivityDetail, data];
        const activityIndex1 = selectedActivityDetail2.findIndex((item) => item.name === data.name)
        selectedActivityDetail2.splice(activityIndex1, 1)
        setSelectedActivityDetail(temp)
        handleActivityDropClose()
    }

    async function handleCopy() {
        formik.handleSubmit()
        const body = {id: editBlockData.id, source: 'sidebar'}

        await copyBlock({
            data: body,
        })

        formik.resetForm()
        handleClose()
    }

    const handleClose = () => {
        formik.resetForm()
        formikActivity.resetForm()
        setOpen(false);
        setExerciseData({})
        setEditExerciseData([])
        dispatch(ClearSingleBlockProgramExercise())
        dispatch(GetListBlocksAction())
    };

    const handleSearch = (e) => {
      e.preventDefault();
      setSearchInput(e.target.value);
    }
    const handleClearSearchInput = () => {
      setSearchInput('');
    }

    const formik = useFormik({
        initialValues: {
            Name: "",
            SecondaryName: "",
            selectActivityGroupF: [],
            Description: "",
            Archived: false,
        },
        onSubmit: values => {
                let exercises_pk = []
                gridRef.current.api.forEachNode((rowNode, index) => {
                    exercises_pk.push(rowNode.data.id)
                })
                let payload = {
                    name: values.Name,
                    secondary_name: values.SecondaryName,
                    exercises_pk: exercises_pk,
                    description: values.Description,
                    archived: values.Archived,
                }
                let payloadData = {
                    name: values.Name,
                    secondary_name: values.SecondaryName,
                    exercises_pk: [],
                    description: values.Description,
                    archived: values.Archived,
                }
                setOpen(false)
                if (Object?.keys(editBlockData)?.length > 0) {
                    dispatch(UpdateBlocksAction({ updateData: payload, id: editBlockData.id }))
                } else {
                    dispatch(CreateBlocksAction(payloadData))
                }
                dispatch(resetActivityAndGroup())
                formik.resetForm()
                formikActivity.resetForm()
                setExerciseData([])
                setEditExerciseData([])
                setEditblockData({})
                setTimeout(() => {
                    handleClose()
                }, 300)

        },
        validationSchema: validationBlockSchema
    })

    const formikActivity = useFormik({
        enableReinitialize: true,
        initialValues: {
            exercise: "",
            set_number: 1,
            weight_lbs: null,
            distance_meters: null,
            reps: null,
            reps_in_reserve: null,
            tempo: null,
            work_time_seconds: null,
            rest_time_seconds: null,
            ExerciseType: "",
            notes: null,
            one_rep_max_percent: null,
            kpi: false
        },
        onSubmit: (values) => {
            if (!editBlockData.id) {
                if (formik.values.Name) {
                    let payload = {
                        name: formik.values.Name,
                        secondary_name: formik.values.SecondaryName,
                        exercises_pk: [],
                        description: formik.values.Description,
                        archived: formik.values.Archived,
                    }
                    dispatch(CreateBlocksAction(payload))
                    setNewBlockCreated(true)
                } else {
                    alert('Add a block name before saving')
                }
            } else {
                let payload = {
                    exercise_pk: values.exercise.id,
                    set_number: Number(values.set_number),
                    weight_lbs: Number(values.weight_lbs),
                    distance_meters: Number(values.distance_meters),
                    reps: Number(values.reps),
                    reps_in_reserve: Number(values.reps_in_reserve),
                    one_rep_max_percent: Number(values.one_rep_max_percent),
                    tempo: values.tempo,
                    work_time_seconds: Number(values.work_time_seconds),
                    rest_time_seconds: Number(values.rest_time_seconds),
                    notes: values.notes,
                    kpi: values.kpi,
                    block_id: editBlockData?.id,
                    source: 'sidebar'
                }
                dispatch(CreateProgramExerciseAction(payload))
            }
        }
    });

    const editModalFun = (data) => {
        if (blockInfo.data.length == 0) {
            dispatch(GetListBlocksAction())
        }
        setEditblockData(data);
        setOpen(true);
        const tempBlock = []
        data?.exercises?.map((data1) => {
            const tempObj = {
                id: data1.id,
                exercise: data1.exercise.name,
                exercise_pk: data1.exercise.id,
                set_number: data1.set_number,
                weight_lbs: Number(data1.weight_lbs),
                distance_meters: Number(data1.distance),
                reps: data1.reps,
                reps_in_reserve: data1.reps_in_reserve,
                one_rep_max_percent: Number(data1.one_rep_max_percent),
                tempo: data1.tempo,
                work_time_seconds: data1.work_time_seconds,
                rest_time_seconds: data1.rest_time_seconds,
                notes: data1.notes,
                kpi: data1.kpi,
                block_id: data.id,
                block_name: data?.name,
                block_secondary_name: data?.secondary_name || data?.secondaryName || '',
                block_description: data?.description,
                block_archived: data?.archived,
            }
            tempBlock.push(tempObj)
        })
        formik.setFieldValue("Name", data?.name)
        formik.setFieldValue("SecondaryName", data?.secondaryName)
        setEditExerciseData([...tempBlock])
        formik.setFieldValue("Description", data?.description)
        formik.setFieldValue("Archived", data?.archived)
    }

    useEffect(() => {
        if (singleBlockExercise.length > 0) {
            if (open) {
                if (editBlockData.id) {
                    let exerciseArray = singleBlockExercise.map((data) => ({
                        id: data.id,
                        exercise: {...data.exercise, label: data.exercise.name},
                        exercise_pk: data.exercisePk,
                        set_number: data.setNumber,
                        weight_lbs: Number(data.weightLbs),
                        distance_meters: Number(data.distanceMeters),
                        reps: data.reps,
                        reps_in_reserve: data.repsInReserve,
                        one_rep_max_percent: Number(data.oneRepMaxPercent),
                        tempo: data.tempo,
                        work_time_seconds: data.workTimeSeconds,
                        rest_time_seconds: data.restTimeSeconds,
                        notes: data.notes,
                        kpi: data.kpi,
                        block_id: editBlockData?.id,
                        block_name: editBlockData?.name,
                        block_secondary_name: editBlockData?.secondaryName,
                        block_archived: editBlockData?.archived,
                    }))
                    gridRef.current?.api?.applyTransaction({ add: exerciseArray })
                    dispatch(ClearSingleBlockProgramExercise())
                }
            }
        }
    }, [singleBlockExercise])

  const onRowValueChanged = useCallback((event) => {
    var data = event.data;
    let payload = {
                exercise_pk: data.exercise_pk,
                exercise: data.exercise,
                set_number: Number(data.set_number),
                weight_lbs: Number(data.weight_lbs),
                distance_meters: Number(data.distance_meters),
                reps: Number(data.reps),
                reps_in_reserve: Number(data.reps_in_reserve),
                one_rep_max_percent: Number(data.one_rep_max_percent),
                tempo: data.tempo,
                work_time_seconds: Number(data.work_time_seconds),
                rest_time_seconds: Number(data.rest_time_seconds),
                notes: data.notes,
                kpi: data.kpi,
                position: data.rowIndex,
                source: 'sidebar',
            }
            dispatch(UpdateProgramExerciseAction({ updateData: payload, id: data.id }))
  }, []);

    return (
    <SidebarBlockTab.Stylesheet>
        <div>
            <Box className="sidebar-search" style={{padding: '0px'}}>
                    <TextField
                        fullWidth
                        onChange={handleSearch}
                        value={searchInput}
                        style={{
                            backgroundColor: "#0000000A",
                            color: "#0000003D",
                        }}
                        placeholder="search"
                        InputProps={{
                            startAdornment: <SearchIcon style={{
                                paddingRight: 1,
                                fill: "#000000",
                                opacity: 0.2,
                                height: '2em',
                                marginRight: '10px' }} />,
                            disableUnderline: true,
                            endAdornment: <DeleteIcon style={{ color: "teal" }} onClick={handleClearSearchInput} />,
                        }}
                    />
            </Box>
            <Divider />
            <Box  style={{maxHeight: '65vh', overflow: 'auto'}}>
                {blockInfo?.loading ?
                    (
                        <Box style={{ display: "flex", padding: "100px", alignItems: "center", justifyContent: "center" }}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <>
                            {
                                list.filter(option => !option.archived).map((item) => (
                                    <>
                                        <div
                                            key={item?.id}
                                            label={item?.name}
                                            value={item?.name}
                                            style={{
                                                py: 1.5,
                                                px: 3,
                                                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                paddingLeft: "24px"
                                            }}
                                        >
                                            <div style={{ cursor: "pointer" }} onClick={() => editModalFun(item)}>
                                                {item?.name}
                                            </div>
                                            <button onClick={() => handleDeleteBlockData(item.id)} className="btn btn-default">
                                                <DeleteIcon style={{ color: "teal" }} />
                                            </button>

                                        </div>
                                    </>
                                ))
                            }
                        </>
                    )
                }
            </Box>
            <Box className="sidebar-bottom">
                <Accordion expanded={expanded} onChange={handleChange}>
                    <AccordionSummary
                      expandIcon={<ExpandLessIcon style={{color: 'white'}} />}
                      style={{justifyContent: 'center', backgroundColor: 'teal'}}
                    >
                      <h3 style={{color: 'white'}}> ARCHIVE </h3>
                    </AccordionSummary>
                    <AccordionDetails style={{maxHeight: '75vh', padding: '0px', overflow: 'scroll'}}>
                    {
                        list.filter(option => option.archived).map((item) => (
                            <>
                                <div
                                    key={item?.id}
                                    label={item?.name}
                                    value={item?.name}
                                    style={{
                                        py: 1.5,
                                        px: 3,
                                        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        paddingLeft: "24px",
                                        paddingBottom: '5px',
                                    }}
                                >
                                    <div style={{ cursor: "pointer" }} onClick={() => editModalFun(item)}>
                                        {item?.name}
                                    </div>
                                    <button onClick={() => handleDeleteBlockData(item.id)} className="btn btn-default">
                                        <DeleteIcon style={{ color: "teal" }} />
                                    </button>

                                </div>
                            </>
                        ))
                    }

                    </AccordionDetails>
                </Accordion>

                <Box
                    style={{
                        textAlign: "center",
                        padding: "14px 0",
                        lineHeight: "normal",
                    }}
                >
                    <Button className="text-primary" onClick={handleOpen}>+ CREATE</Button>
                </Box>

                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                        disableEnforceFocus
                    >
                        <Box
                            style={{
                                backgroundColor: "white",
                                border: "2px solid #000",
                                boxShadow: 24,
                                padding: '30px 30px 5px 30px',
                                minWidth: '75vw',
                                overflow: 'scroll',
                            }}
                        >
                            {
                                Object?.keys(editBlockData)?.length === 0 ? <h3>Create Block</h3> : <h3>Edit Block</h3>
                            }
                            <form onSubmit={formik.handleSubmit}>
                                <TextField
                                    fullWidth
                                    name="Name"
                                    onChange={formik.handleChange}
                                    value={formik.values.Name}
                                    style={{ marginTop: "10px" }}
                                    type="text"
                                    label="Name"
                                    variant="filled"
                                    error={formik.errors.Name && formik.touched.Name}
                                    helperText={formik.touched.Name && formik.errors.Name}
                                />
                                <TextField
                                    fullWidth
                                    name="SecondaryName"
                                    onChange={formik.handleChange}
                                    value={formik.values.SecondaryName}
                                    style={{ marginTop: "10px" }}
                                    type="text"
                                    label="Secondary Name"
                                    variant="filled"
                                    error={formik.errors.SecondaryName && formik.touched.SecondaryName}
                                    helperText={formik.touched.SecondaryName && formik.errors.SecondaryName}
                                />
                                <br />


                                        <Box
                                            style={{
                                                backgroundColor: "white",
                                                boxShadow: 24,
                                                padding: '20px 0px 0px 0px',
                                                minWidth: '75vw',
                                                overflow: 'scroll',
                                                maxHeight: '50vh',
                                            }}
                                        >
                                            <div className="ag-theme-alpine" style={{ height: '40vh' }}>
                                              <AgGridReact
                                                rowData={editExerciseData}
                                                getRowId={params => params.data.id}
                                                columnDefs={columnDefs}
                                                editType={'fullRow'}
                                                rowDragManaged={true}
                                                onRowValueChanged={onRowValueChanged}
                                                animateRows={true}
                                                onRowDragEnd={onRowDragEnd}
                                                ref={gridRef}
                                                stopEditingWhenCellsLoseFocus={true}
                                                >
                                              </AgGridReact>
                                            </div>
                                        </Box>

                                    <Box style={{
                                        backgroundColor: "white",
                                        boxShadow: 24,
                                        minWidth: '75vw',
                                        overflow: 'scroll',
                                        maxHeight: '50vh',
                                    }}>
                                        <form onSubmit={formikActivity.handleSubmit} style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                            {<>
                                                {selectedActivityDetail?.map((data) => (
                                                    <>
                                                        {data?.name === "exercise" ? (
                                                        <>
                                                            <TableCell>
                                                                <Autocomplete
                                                                  disablePortal
                                                                  value={formikActivity.values.exercise}
                                                                  options={exerciseInfo?.data?.map((option) => ({label: option.name, id: option.id}))}
                                                                  getOptionLabel={(option) => option?.label || ''}
                                                                  style={{width: '15vw'}}
                                                                  ListboxProps={{ style: { maxHeight: 300 } }}
                                                                  renderInput={(params) => <TextField {...params}  label="Exercise" variant='filled' />}
                                                                  onChange={(e, value) => formikActivity.setFieldValue("exercise", value)}
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <CreateExercise/>
                                                            </TableCell>
                                                        </>
                                                        ) :
                                                            data?.name === "reps" && data?.name === "weight_lbs" && data?.name === "set_number" && data?.name === "rest_time_seconds" && data?.name === "work_time_seconds" ? (
                                                                <TableCell align="center">
                                                                    <TextField
                                                                        type="number"
                                                                        name={data?.name}
                                                                        onChange={formikActivity.handleChange}
                                                                        value={formikActivity.values[data?.name]}
                                                                        style={{ width: "80px" }}
                                                                        variant="filled"
                                                                        placeholder={data?.label}
                                                                    />
                                                                </TableCell>) :
                                                                data?.name === "kpi" ? (
                                                                    <TableCell align="center">
                                                                        <Checkbox
                                                                            value={formikActivity.values.kpi}
                                                                            name="kpi"
                                                                            onChange={formikActivity.handleChange}
                                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                                        />
                                                                    </TableCell>
                                                                ) :
                                                                    data?.name === "notes" ? (
                                                                    <TableCell align="center">
                                                                        <TextField
                                                                            type="text"
                                                                            name={data?.name}
                                                                            placeholder={data?.label}
                                                                            onChange={formikActivity.handleChange}
                                                                            value={formikActivity.values[data?.name]}
                                                                            style={{ width: "300px" }}
                                                                            variant="filled"
                                                                        />
                                                                    </TableCell>
                                                                    ) : (
                                                                    <TableCell align="center">
                                                                        <TextField
                                                                            type="number"
                                                                            name={data?.name}
                                                                            placeholder={data?.label}
                                                                            onChange={formikActivity.handleChange}
                                                                            value={formikActivity.values[data?.name]}
                                                                            style={{ width: "100px" }}
                                                                            variant="filled"
                                                                        />
                                                                    </TableCell>
                                                                    )
                                                        }
                                                    </>
                                                )
                                                )}
                                                <TableCell align="center">
                                                    <Button type="submit" variant="contained" color="primary" onSubmit={formikActivity.handleSubmit}>ADD</Button>
                                                </TableCell>
                                                {/*<TableCell align="center">
                                                    <Button style={{ backgroundColor: 'transparent' }} variant="contained" onClick={handleClearActivity}>Clear</Button>
                                                </TableCell>*/}
                                            </>}
                                        </form>
                                    </Box>
                                    <Box>
                                        {selectedActivityDetail?.map((data) => (
                                            <>
                                                <Button disabled variant="contained" size="small" style={{ marginLeft: "10px" }}>
                                                    {data.label}
                                                </Button>
                                            </>
                                        ))}

                                        <Button aria-describedby={id} style={{ marginLeft: "10px" }} onClick={handleActivityDropOpen}>
                                            <Add />
                                        </Button>
                                        <Popover
                                            id={id}
                                            open={openaActivityDetailName}
                                            anchorEl={anchorEl}
                                            onClose={handleActivityDropClose}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                        >
                                            {selectedActivityDetail2?.map((data1) => (
                                                <>
                                                    <MenuItem onClick={() => handleActivityDetailNameChange(data1)}>{data1?.label}</MenuItem>
                                                </>
                                            ))}

                                        </Popover>
                                        <Button onClick={handleRemoveActivity}>
                                            <Remove />
                                        </Button>
                                    </Box>
                                    <br />
                                    <TextField
                                        type="text"
                                        name="Description"
                                        fullWidth
                                        onChange={formik.handleChange}
                                        value={formik.values.Description}
                                        style={{ marginTop: "10px" }}
                                        multiline
                                        rows={1}
                                        label="Description"
                                        variant="filled"
                                        error={formik.errors.Description && formik.touched.Description}
                                        helperText={formik.touched.Description && formik.errors.Description}
                                    />
                                    <br />
                                    <Box style={{ textAlign: 'right', margin: '10px 0', display: 'flex', justifyContent: 'flex-end' }}>
                                        <FormGroup  style={{margin: '0px 20px'}}>
                                          <FormControlLabel control={<Switch checked={formik.values.Archived} name='Archived' value={formik.values.Archived} onChange={formik.handleChange} style={{color: 'teal'}}/>} label="Archive" labelPlacement="start" />
                                        </FormGroup>
                                        <Button variant="outlined" style={{margin: '0px 20px'}} onClick={handleCopy}>
                                            Copy
                                        </Button>
                                        <Button variant="contained" style={{margin: '0px 20px'}} color="primary" type="submit" onSubmit={formik.handleSubmit}>
                                            Save
                                        </Button>
                                        <Button variant="outlined" style={{ marginLeft: '20px',  color: '#0000008A' }} onClick={handleClose}>
                                            Cancel
                                        </Button>
                                    </Box>
                            </form>
                        </Box>
                    </Modal>
            </Box >
        </div >
      </SidebarBlockTab.Stylesheet>
    )
}

SidebarBlockTab.Stylesheet = styled.div`
    .MuiInputBase-input{
        font-size: 30px
    }
`

export default SidebarBlockTab
