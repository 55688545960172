import React, {useContext, useEffect} from 'react';
import { Box, Modal, TextField, Button } from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { CreateProgramAction, GetListProgramAction, SelectedProgramId, GetListProgramWeeksAction, GetListProgramDaysAction } from 'Redux/Action/programBuilderAct';
import { useDispatch, useSelector } from 'react-redux';
import { LayoutContext} from 'common/ducks'
import useApi from 'common/hooks/useApi'
import Dialog from '@material-ui/core/Dialog'

const validationSchema = Yup.object().shape({
    Name: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    Description: Yup.string()
        .notRequired(),
});

function CreateProgram({ open, setOpen }) {
    const { programs, programID } = useSelector(state => state.rootReducer.programBuilder)
    const { setAppLoading } = useContext(LayoutContext)
    const dispatch = useDispatch();
    const [copiedProgram, setCopiedProgram] = React.useState(false)
    const [program, setProgram] = React.useState()
    const [createdProgram, setCreatedProgram] = React.useState()
    const formik = useFormik({
        initialValues: {
            Name: "",
            Description: ""
        },
        onSubmit: (values, { resetForm }) => {
            const payload = {
                name: values.Name,
                description: values.Description
            }
            setOpen(false);
            dispatch(CreateProgramAction(payload))
            resetForm()
            setCreatedProgram(true)
        },
        validationSchema
    })

    const [{ loading: copyLoading }, copyProgram] = useApi(
            {
              method: 'POST',
              url: 'metrics/program/copy_program/',
            },
            { manual: true }
          )

    const handleClose = () => {
        setOpen(false);
    }

    async function handleCopyProgram() {
        if (programID) {
            await copyProgram({
                data: {
                    id: programID,
                    name: formik.values.Name,
                    description: formik.values.Description},
            }).then((response) => {
                if( response?.status === 200 ){
                    dispatch(GetListProgramAction())
                    dispatch(GetListProgramWeeksAction())
                    dispatch(GetListProgramDaysAction())
                    setOpen(false)
                    setProgram(response.data.name)
                    setCopiedProgram(true)
                    setTimeout(() => {
                        dispatch(SelectedProgramId(response.data.id))
                        setCopiedProgram(false)
                        }, 5000)
                }
            })
        }
        else {
            alert('Select a Program First')
        }
    }

    useEffect(() => {
        if (createdProgram) {
            const list = programs?.data?.map((item) => Number(item?.id))
            const newId = Math.max(...list)
            const findObj =  programs?.data?.find(item => item?.id === newId)
            setCreatedProgram(false)
            dispatch(SelectedProgramId(findObj.id))
            dispatch(GetListProgramWeeksAction())
            dispatch(GetListProgramDaysAction())
        }
    }, [programs])

    useEffect(() => {
      setAppLoading(copyLoading)
    }, [setAppLoading, copyLoading])

    return (
        <Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                disableEnforceFocus
            >
                <Box
                    style={{
                        backgroundColor: "white",
                        border: "2px solid #000",
                        boxShadow: 24,
                        padding: '30px',
                        minWidth: '40vw',
                        overflow: 'scroll'
                    }}
                >
                    <h2>Create Program</h2>
                    <form onSubmit={formik.handleSubmit}>
                        <TextField
                            fullWidth
                            name="Name"
                            onChange={formik.handleChange}
                            value={formik.values.Name}
                            style={{ marginTop: "10px" }}
                            type="text"
                            label="Name"
                            variant="filled"
                            error={formik.errors.Name && formik.touched.Name}
                            helperText={formik.errors.Name}
                        />
                        <br />
                        <TextField
                            type="text"
                            name="Description"
                            fullWidth
                            onChange={formik.handleChange}
                            value={formik.values.Description}
                            style={{ marginTop: "10px" }}
                            multiline
                            rows={6}
                            label="Description"
                            variant="filled"
                            error={formik.errors.Description && formik.touched.Description}
                            helperText={formik.errors.Description}
                        />
                        <br />
                        <Box style={{ textAlign: 'right', margin: '10px 0' }}>
                            <Button variant="outlined" color="primary" type="submit" style={{ marginLeft: "2vw", width: "10vw", border: "2px solid #0B545E" }}>
                                Start<br />Blank
                            </Button>
                            <Button variant="contained" color="primary" onClick={() => handleCopyProgram()} style={{ marginLeft: "1vw", width: "10vw" }}>
                                Use<br />Current
                            </Button>
                            <Button variant="text" style={{ color: '#0000008A' }} onClick={handleClose}>
                                Cancel
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Modal >
            <Dialog
              open={copiedProgram}
              onClose={() => setOpen(false)}
              >
                <div style={{minWidth: '40vw', padding: '5%', display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '10em'}}>
                    <h2 className="title" >{program} created</h2>
                </div>
            </Dialog>
        </Box >
    );
}

export default CreateProgram;
