import React, { useContext, useEffect, useState } from 'react'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import MUIDialog from '@material-ui/core/Dialog'
import MUIDialogContent from '@material-ui/core/DialogContent'
import TextField from '@material-ui/core/TextField'
import Toolbar from '@material-ui/core/Toolbar'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { KeyboardDatePicker } from '@material-ui/pickers'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { useFormik } from 'formik'
import moment from 'moment'
import styled from 'styled-components'
import { SOURCES } from 'activity/constants'
import {
  ActivityLogContext,
  hideDialogAction,
  saveDataAction,
} from 'activity/ducks'
import { LayoutContext } from 'common/ducks'
import useApi from 'common/hooks/useApi'
import DailyCache from 'common/utils/DailyCache'
import LRUCache from 'common/utils/LRUCache'
import theme from 'styles/theme'

const cachedActivityNames = new LRUCache('activityNames', 50)
const cachedSessionActivities = new DailyCache('sessionActivities')

const UNITS = {
  ft: 0.3048,
  km: 1000,
  m: 1,
  mi: 1609.34,
  yd: 0.9144,
}

function ActivityDialog() {
  const { FITBIT, SIDEKICK_CORE } = SOURCES
  const endpoint = 'metrics/activities/'
  const { state, dispatch } = useContext(ActivityLogContext)
  const { setAppLoading } = useContext(LayoutContext)
  const [conversionFactor, setConversionFactor] = useState(UNITS.m)
  const data = state.dialog?.data
  const ui = state.dialog?.ui
  const [checked, setChecked] = useState(data?.kpi)
  const [{ loading: createLoading }, create] = useApi(
    {
      method: 'POST',
      url: endpoint,
    },
    { manual: true }
  )

 useEffect(() => {
      if (data){
        setChecked(data.kpi)
      }
    }, [data])

  const [{ loading: updateLoading }, update] = useApi(
    {
      method: 'PUT',
    },
    { manual: true }
  )

  function handleChange(event) {
    setConversionFactor(event.target.value)
  }

  function handleClose() {
    return dispatch(hideDialogAction())
  }

  async function handleSave(values) {
    const { day, distance, index, restTime, weight, workTime, tags, ...rest } = values

    const body = {
      ...rest,
      day: moment(day).toISOString(),
      distanceMeters: distance
        ? (distance * conversionFactor).toFixed(2)
        : null,
      restTimeSeconds: restTime,
      user: data?.user,
      weightLbs: weight,
      workTimeSeconds: workTime,
      kpi: checked,
      tags: tags || []
    }

    if (data?.id) {
      const response = await update({
        data: body,
        url: `${endpoint}${data?.id}/`,
      })

      dispatch(
        saveDataAction({
          index,
          ...response.data,
        })
      )
    } else {
      const response = await create({
        data: body,
      })

      dispatch(
        saveDataAction({
          index,
          ...response.data,
        })
      )

      if (values.name) {
        cachedSessionActivities.set(values.name, values)
      }
    }

    dispatch(hideDialogAction())

    if (values.name) {
      cachedActivityNames.put(values.name)
    }
  }

  const {
    reps,
    setNumber = 0,
    weight,
  } = cachedSessionActivities.get(data.name) || {}

  const initialValues = {
    ...data,
    ...(data?.setNumber
      ? { setNumber: data.setNumber }
      : { setNumber: setNumber + 1 }),
    ...(data?.reps ? { reps: data.reps } : { reps }),
    ...(data?.weight ? { weight: data.weight } : { weight }),
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: handleSave,
  })

  const defaultTextFieldOptions = {
    fullWidth: true,
    margin: 'normal',
    onChange: formik.handleChange,
    variant: 'outlined',
  }

  const distanceFieldOptions = {
    fullWidth: false,
    margin: 'normal',
    onChange: formik.handleChange,
    variant: 'outlined',
  }

  function handleAutocompleteChange(event, value) {
    formik.handleChange({
      target: {
        name: 'name',
        value,
      },
    })
  }

  useEffect(() => {
    setAppLoading(createLoading || updateLoading)
  }, [createLoading, setAppLoading, updateLoading])

  const handleKpi = (event) => {
    setChecked(event.target.checked)
    formik.values.kpi = event.target.checked
  }

  return (
    <ActivityDialog.MUIDialog fullScreen open={ui?.open} onClose={handleClose}>
      <form>
        <ActivityDialog.AppBar>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
            <ActivityDialog.Title>Log Activity</ActivityDialog.Title>
            <Button color="inherit" onClick={formik.handleSubmit}>
              Save
            </Button>
          </Toolbar>
        </ActivityDialog.AppBar>

        <ActivityDialog.Content>
        <div>
          <KeyboardDatePicker
            style={{ width: '80%', paddingRight: '3%'}}
            format="L"
            fullWidth
            inputVariant="outlined"
            label="Day"
            name="day"
            onChange={(date, value) => formik.setFieldValue('day', value)}
            value={formik.values.day}
            />
          <FormControlLabel
            control={
              <Checkbox
              />}
            label="KPI"
            labelPlacement="start"
            onChange={handleKpi}
            checked={checked}
            value={formik.values.kpi}
            style={{ marginLeft: '1%', marginTop: '7px'}}
          />
         </div>
          <Autocomplete
            options={cachedActivityNames.list()}
            onInputChange={handleAutocompleteChange}
            renderInput={(params) => (
              <TextField
              {...params}
              {...defaultTextFieldOptions}
              label="Name"
              name="name"
              type="text"
              value={formik.values.name}
              />
              )}
            value={formik.values.name}
          />
          {data?.source === SIDEKICK_CORE && (
            <>
              <TextField
                {...defaultTextFieldOptions}
                style={{ width: '33%' }}
                label="Sets"
                name="setNumber"
                type="number"
                value={formik.values.setNumber}
              />
              <TextField
                {...defaultTextFieldOptions}
                style={{ width: '32.5%', marginLeft: '1%' }}
                label="Weight (lbs)"
                name="weight"
                type="number"
                value={formik.values.weight}
              />
              <TextField
                {...defaultTextFieldOptions}
                style={{ width: '32.5%', marginLeft: '1%' }}
                label="Reps"
                name="reps"
                type="number"
                value={formik.values.reps}
              />
              <TextField
                {...defaultTextFieldOptions}
                style={{ width: '49.5%' }}
                label="Work Time (seconds)"
                name="workTime"
                type="number"
                value={formik.values.workTime}
              />
              <TextField
                {...defaultTextFieldOptions}
                style={{ width: '49.5%', marginLeft: '1%'}}
                label="Rest Time (seconds)"
                name="restTime"
                type="number"
                value={formik.values.restTime}
              />
            </>
          )}
          {data?.source === FITBIT && (
            <TextField
              {...defaultTextFieldOptions}
              label="Calories Burned"
              name="calories"
              type="number"
              value={formik.values.calories}
            />
          )}
          <>
            <TextField
              {...distanceFieldOptions}
              style={{ width: '74.5%' }}
              label="Distance"
              name="distance"
              type="number"
              value={formik.values.distance}
            />
            <FormControl
              variant="outlined"
              style={{ marginTop: '15px', marginLeft: '1%', width: '24.5%' }}
            >
              <Select value={conversionFactor} onChange={handleChange}>
                {Object.keys(UNITS).map((k) => (
                  <MenuItem key={k} value={UNITS[k]}>
                    {k}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
          <TextField
                {...defaultTextFieldOptions}
                style={{ width: '49.5%'}}
                label="Tempo"
                name="tempo"
                value={formik.values.tempo}
          />
          <TextField
                {...defaultTextFieldOptions}
                style={{ width: '49.5%', marginLeft: '1%'}}
                label="RIR"
                name="rir"
                type="number"
                value={formik.values.rir}
          />
          <TextField
            {...defaultTextFieldOptions}
            label="Notes"
            multiline
            name="notes"
            rows={4}
            value={formik.values.notes}
          />
        </ActivityDialog.Content>
      </form>
    </ActivityDialog.MUIDialog>
  )
}

ActivityDialog.AppBar = styled(AppBar)`
  position: static;
  padding-top: env(safe-area-inset-top);
`
ActivityDialog.Content = styled(MUIDialogContent)`
  margin-top: calc(env(safe-area-inset-top) + 56px + ${theme.spacing(3)}px);
`
ActivityDialog.MUIDialog = styled(MUIDialog)`
  padding-bottom: env(safe-area-inset-bottom);
`
ActivityDialog.Title = styled.h3`
  color: ${theme.palette.primary.contrastText};
  flex: 1;
  margin-bottom: 0;
  margin-left: ${theme.spacing(2)}px;
`

export default ActivityDialog
