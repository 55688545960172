import React, { useContext, useState, useEffect } from 'react'
import { ActivityLogContext } from 'activity/ducks'
import Layout from 'common/components/Layout'
import ProgramDialog from 'activity/components/ProgramDialog'
import ProgramCalendar from 'activity/components/ProgramCalendar'
import useActivity from 'activity/hooks/useActivity'
import ProgramCards from 'activity/components/ProgramCards'
import { UserContext } from 'account'
import { useLocation } from 'react-router-dom'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import ActivityDialog from 'activity/components/Dialog'
import useApi from 'common/hooks/useApi'

function Activity() {
  const user = useContext(UserContext)
  const [planName, setPlanName] = useState('')
  const [showPaymentModal, setShowPaymentModal] = useState(false)
  const queryParams = new URLSearchParams(window.location.search)
  const paymentStatus = queryParams.get("state")
  const location = useLocation()
  const { loading, data, state, dispatch, openProgramDialog, programList } =
    useActivity()

    const [{ data: subscriptionsData = {} }] =  useApi('subscriptions/')

    if (location.pathname === '/' && user.isTrainer) {
        window.location.href = '/trainerDashboard'
    }

    useEffect(() => {
      if (paymentStatus === 'succeeded') {
        if (subscriptionsData.subscriptions) {
          const subscription = subscriptionsData.subscriptions?.find((s) => s.status === 'active' || 'trialing')
          if (subscription) {
            setShowPaymentModal(true)
            setPlanName(subscription.program_name || '')
          }
        }
      }
    }, [subscriptionsData.subscriptions])
  return (
    <ActivityLogContext.Provider value={{ state, dispatch }}>
      <Layout>
        <ProgramCalendar loading={loading} data={data} />
        <ProgramCards programList={programList} onClick={openProgramDialog} />

        <ActivityDialog />
        <ProgramDialog />
      </Layout>
      <Dialog open={showPaymentModal} onClose={() => setShowPaymentModal(false)} >
        <DialogContent>
          <DialogContentText>
          <>
            <strong style={{ fontWeight: 'bolder' }}>
              Welcome to Sidekick!
            </strong>
            <br />
            <br />
            <strong style={{ fontWeight: 'bolder' }}>
              Subscription added!
            </strong>
            <br />
            <br />
            {!user.isTrainer && `${planName
              ? `${planName} has been added to your Program page`
              :'You now have access to the platform'} `}
            <br />
            <br />
            Watch this to help get started
            <br />
            <br />
            <iframe src="https://drive.google.com/file/d/1xG3MVuSkCxe0ZBpplsft1drJFaCMdEhm/preview"
              title="Watch Here"
              style={{ width: '100%' }}
            />
            </>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </ActivityLogContext.Provider>
  )
}

export default Activity
